import formatISO from "date-fns/format";
import { enGB, ja } from "date-fns/locale";
import { TFunction } from "i18next";

import { ApiErrorType } from "@pdpp/lib-planta";

import { userPermissions$ } from "../features/opp/state";
import { OPP_AUTHZ } from "./runtimeConfig";

export const displayDateFromISO = (value: string, lang: string): string =>
  formatISO(new Date(value), "dd MMM yyyy", { locale: getLocale(lang) });

export const getFiscalYear = (): [Date, Date] => {
  const d = new Date();
  const y = d.getFullYear();
  const fy = d.getMonth() >= 8 ? y : y - 1;
  const fy0 = new Date(fy, 8, 1); // September 1
  const fy1 = new Date(fy + 1, 7, 31); // August 31
  return [fy0, fy1];
};

export function debounce<A = unknown, R = void>(
  fn: (args: A) => R,
  ms: number,
): (args: A) => void {
  let timer: ReturnType<typeof setTimeout> | null = null;

  return (args: A): void => {
    if (timer) {
      clearTimeout(timer);
    }

    timer = setTimeout(() => {
      fn(args);
      timer = null; // Clear the timer after execution
    }, ms);
  };
}

export const getLocale = (lang: string): Locale => {
  if (lang === "ja-JP") {
    return ja;
  }

  return enGB;
};

export type ApiErrors = {
  errors: ApiErrorType[];
};

export function parseApiErrors(
  errorsObj: ApiErrorType[],
  t: TFunction,
): string[] {
  return errorsObj.map((error) =>
    t(`serverErrors.${error.code}`, error.arguments),
  );
}

export function hasOppRole(role: string): boolean {
  if (!OPP_AUTHZ) {
    return true;
  }
  const userPermissions = userPermissions$.value;
  return userPermissions?.roles?.includes(role);
}

/**
 * Compares two time strings in the formats "XXhYY" or "X,XXXhYY" where X is hours and Y is minutes
 * Returns true if the first time value is greater than the second
 * @param value1 First time string to compare (format: "XXhYY" or "X,XXXhYY")
 * @param value2 Second time string to compare (format: "XXhYY" or "X,XXXhYY")
 */
export function compareTimeStrings(value1: string, value2: string): boolean {
  const parseAndConvert = (str: string): number => {
    // Remove commas and split by 'h'
    const cleanStr = str.replace(/,/g, "");
    const [hours, minutes] = cleanStr
      .split("h")
      .map((part) => parseInt(part, 10));
    return (hours ?? 0) * 60 + (minutes ?? 0);
  };

  return parseAndConvert(value1) > parseAndConvert(value2);
}
