import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";

import SSRIcon from "@ingka/ssr-icon";
import DocumentPencilIcon from "@ingka/ssr-icon/paths/document-pencil";
import Text from "@ingka/text";
import WarningTriangleIcon from "@ingka/ssr-icon/paths/warning-triangle";

import { css } from "../__generated-styled-system/css";
import { period$, periodBudget$, periodPlanTotal$ } from "../features/period";
import { orgId$ } from "../features/org/state";
import { getHeadcountRequest } from "../features/plan/api";
import { View } from "../types/client";
import { headcount$ } from "../features/plan/state";
import { compareTimeStrings } from "../utils";

const wrapperStyles = css({
  display: "grid",
  gridTemplateColumns: "min-content 1fr",
  gridTemplateRows: "min-content 1fr",
  gridRowGap: "space100",
});

const periodPlanTextStyles = css({
  marginLeft: "space50!",
});

const textStyles = css({ gridColumn: "-1 / 1" });

const warningIconStyles = css({
  fill: "#F26A2F!", // colourSemanticCaution
  width: "1.2rem!",
  paddingBottom: "space25!",
});

export const PlanPeriod: FC = () => {
  const { t } = useTranslation();

  const orgId = orgId$.value;
  const period = period$.value;
  const headcount = headcount$.value;
  const periodBudget = periodBudget$.value;
  const periodPlanTotal = periodPlanTotal$.value;

  useEffect(() => {
    getHeadcountRequest({
      view: View.Period,
      orgNodeId: orgId ?? "",
      period: [
        format(period[0], "yyyy-MM-dd"),
        format(period[1], "yyyy-MM-dd"),
      ],
    });
  }, [orgId, period, headcount]);

  const showWarningIcon: boolean =
    Boolean(periodBudget) && compareTimeStrings(periodPlanTotal, periodBudget);

  return (
    <div className={wrapperStyles}>
      <Text className={textStyles} tagName="p" bodySize="s">
        {t("plan.periodPlan")}
      </Text>
      <SSRIcon
        paths={showWarningIcon ? WarningTriangleIcon : DocumentPencilIcon}
        className={showWarningIcon ? warningIconStyles : undefined}
      />
      <Text className={periodPlanTextStyles} tagName="span" headingSize="xs">
        {periodPlanTotal}
      </Text>
    </div>
  );
};
